<script lang="ts">
  export let small = false;
</script>

<div class="loader-container" class:small>
  <span class="bulma-loader-mixin" />
</div>

<style lang="scss" scoped>
  @mixin loader() {
    animation: spinAround 0.5s infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 9999px;
    border-right-color: transparent;
    border-top-color: transparent;
    display: block;
    height: 3em;
    position: relative;
    width: 3em;
  }

  .bulma-loader-mixin {
    @include loader;
  }

  .loader-container {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 10vw;
  }

  .loader-container.small {
    height: 100%;
    width: 100%;
  }
</style>
